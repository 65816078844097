import Alpine from 'alpinejs'
import Lenis from 'lenis'

import './parallax-images'
import './intersection'
import './shop'

Alpine.start()

// Initialize Lenis
const lenis = new Lenis({
  autoRaf: true,
})
