// Global IntersectionObserver
let intersectObserver

document.addEventListener('DOMContentLoaded', () => {
  // Set up the IntersectionObserver
  intersectObserver = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      updateIntersectionProgress(entry)
    })
  }, {
    // Optional: Consider configuring the root margin or thresholds based on specific needs
  })

  // Observe all relevant elements
  observeElements()
})

function observeElements () {
  const intersectSections = document.querySelectorAll('[data-intersect]')
  intersectSections.forEach(section => {
    intersectObserver.observe(section)
  })
}

// Function to handle updating the intersection progress
function updateIntersectionProgress (entry) {
  const viewportHeight = window.innerHeight
  const boundingClientRectTop = entry.boundingClientRect.top
  const elementHeight = entry.boundingClientRect.height
  const totalScroll = viewportHeight + elementHeight
  const scrolled = viewportHeight - boundingClientRectTop
  let scrollPercent = (scrolled / totalScroll) * 100

  if (entry.target.dataset.intersect !== 'completed') {
    if (boundingClientRectTop + elementHeight < 0) {
      scrollPercent = 100
    } else if (boundingClientRectTop > viewportHeight) {
      scrollPercent = 0
    }

    scrollPercent = Math.max(0, Math.min(scrollPercent, 100)).toFixed(2)

    entry.target.setAttribute('data-intersect-progress', scrollPercent)
    entry.target.style.setProperty('--intersectionProgress', scrollPercent)

    // Unobserve if the data-intersect attribute is 'once' and scrollPercent reaches 100
    if (entry.target.dataset.intersect === 'once' && scrollPercent === 100) {
      // Use requestAnimationFrame to delay unobserve until the next frame
      requestAnimationFrame(() => {
        if (scrollPercent >= 100) {
          entry.target.setAttribute('data-intersect', 'completed')
        }
      })
    }
  }
}

// Handle resize or scroll
function handleResizeOrScroll () {
  // This function is throttled using debounce to avoid excessive calculations
  intersectObserver.disconnect()  // Temporarily disconnect the observer
  observeElements()  // Re-observe to recalculate intersections
}

// Add resize and scroll listeners with debouncing
window.addEventListener('resize', handleResizeOrScroll)
window.addEventListener('scroll', handleResizeOrScroll)
