import Alpine from 'alpinejs'
import Lenis from '@studio-freight/lenis'

import './parallax-images'
import './intersection'

Alpine.start()

const lenis = new Lenis()

function raf (time) {
  lenis.raf(time)
  requestAnimationFrame(raf)
}

requestAnimationFrame(raf)
